#event_ticket_category_table {

  .material-icons {
    font-size: 24px;
  }

  .material-icons-asc {
    transform: rotate(180deg);
  }

  td {
    vertical-align: middle;
  }

  .product-name, .product-sku {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 300px;
  }

  .sort {
    cursor: pointer;
  }

}


.product-qty-container {

  @include media-breakpoint-down(sm) {
    margin-top: 1rem;
    display: flex;
  }

  input {
    width: 50px;
    display: inline-block;
    text-align: center;

    @include media-breakpoint-down(sm) {
      flex-grow: 1;
    }
  }

  span {
    width: 95px;
    display: inline-block;

    @include media-breakpoint-down(sm) {
      flex-grow: 1;
    }
  }

  .product-qty-button {
    user-select: none;
    width: auto;

    @include media-breakpoint-down(sm) {
      flex: 1;
    }

    i {
      color: $primary;
      font-size: 30px;

      &:hover {
        color: darken($gray-800, 5%);
      }

      &:active {
        color: darken($gray-800, 10%);
      }
    }

    &:hover {
      background-color: darken($gray-200, 5%);
    }

    &:active {
      background-color: darken($gray-200, 10%);
    }

    &:focus {
      box-shadow: none;
    }
  }
}

.product-description, .allergens {
  color: #666666;
}

.product-title, .product-description, .allergens, .location-description {
  word-break: break-word;
  hyphens: auto;
}

.product-image {
  border-radius: 0.25rem;
}

.product-image-wrapper {
  height: 190px;
  @include media-breakpoint-down(sm) {
    height: 90px;
  }
}

.product-image-wrapper img {
  object-fit: contain;
  height: 100%;
  width: 100%;
  cursor: pointer;
}

#product-extra-tabs {
  box-shadow: unset !important;
  border-radius: unset;
}

#event_ticket_category_form .card-box {
  border-top: none;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, .00) !important;;
  -moz-border-radius-topleft: unset;
  -moz-border-radius-topright: unset;
}

ul#product-category-list-sortable i.material-icons {
  color: #212529;
}


.product-lightbox-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  opacity: .5;
}

.product-lightbox-wrapper {
  z-index: 1050;
  position: fixed;
  left: 50%;
  top: 50%;
  margin: 0 auto;
  transform: translate(-50%, -50%);

  .line-1 {
    position: absolute;
    display: block;
    height: 30px;
    width: 3px;
    background: #ffffff;
    top: -30px;
    right: 10px;
    -webkit-transform: rotate(45deg);
  }

  .line-2 {
    position: absolute;
    display: block;
    height: 30px;
    width: 3px;
    background: #ffffff;
    top: -30px;
    right: 10px;
    -webkit-transform: rotate(-45deg);
  }
}

.blur-background #content {
  filter: blur(5px);
}
.shop-employee-mode {
  .card-box {
    padding: 10px 15px 0 15px;
  }

  .product-row {
    padding-left: 5px;
    padding-right: 5px;

    .col-4, .col-sm-3, .col-xl-2 {
      padding-left: 5px;
      padding-right: 5px;
    }

    .shop-product-container {
      padding-bottom: 10px;

      .shop-product {
        height: 100%;
        overflow: hidden;
        max-height: 100px;
        min-height: 100px;
        word-wrap: break-word;
        hyphens: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: safe center;

        &.alert-primary {
          color: #000000;
        }

        p {
          margin-bottom: 0;
        }

        &.focus, &:active {
          filter: brightness(90%);
        }
      }
    }
  }
}
#product-category-list {
  .material-icons {
    cursor: pointer;
  }
}

.product-category-link-select {
  width: 300px;
}

.product-category-link-delete-button {
  cursor: pointer;
}

#navbar-product-category {

  width: 100%;
  position: sticky;
  top: 0;

  @include media-breakpoint-only(sm) {
    width: 540px;
  }
  @include media-breakpoint-only(md) {
    width: 720px;
  }
  @include media-breakpoint-only(lg) {
    width: 960px;
  }
  @include media-breakpoint-only(xl) {
    width: 1140px;
  }
  z-index: 100;
}

.product-category-title {
  @include media-breakpoint-down(sm) {
    margin-left: 15px;
  }
}

#product-category-list #deactivated-product-category li.list-group-item {
  background-color: #EDF2F6;
}

.product-category-product-tools {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: safe center;

  .product-color-picker {
    cursor: pointer;
  }
}

#admin-content-frame {
  ul#product-category-link-list-sortable {
    li.product-category-link {
      word-break: break-all;
    }
  }
}

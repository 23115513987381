body[p='organizer_location_dashboard'] {
  .card-box {
    padding: 15px;
  }
}

#location-dashboard-turnover-sum {
  height: 190px;
  display: flex;
  flex-direction: column;

  #turnover-title {
    font-weight: bold;
    font-size: 1.4rem;
    padding-bottom: 10px;
    margin-bottom: 10px;

    #turnover-title-period {
      font-size: 0.8rem;
      display: block;
    }
  }

  #turnover-sum {
    font-size: 2.3rem;
    text-align: center;
    font-weight: bold;
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;

    @include media-breakpoint-between(sm, md) {
      flex-direction: column;
    }

    @include media-breakpoint-only(sm) {
      font-size: 1.4rem;
    }

    @include media-breakpoint-only(md) {
      font-size: 2rem;
    }

    @include media-breakpoint-only(lg) {
      font-size: 1.7rem;
    }

    span {
      font-size: 1rem;
      display: inline-block;
      margin-right: 1rem;
      margin-top: 15px;
    }
  }
}

.location-dashboard-pie {
  height: 160px;
  display: flex;
  align-items: center;
  justify-content: center;
}

#location-dashboard-turnover-sum, .location-dashboard-pie {
  .loader {
    border-color: #dcdcdc;
    border-top-color: $primary;
  }
}

body, html {
  height: 100%;
  background-color: #EDF2F6;
  font-family: 'Ubuntu', sans-serif;
}

html.embed {
  background-color: #ffffff;

  body {
    background-color: #ffffff;
  }

  div.card-box {
    box-shadow: unset !important;
  }
}


.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, .9);
}

.navbar-brand {
  img {
    max-height: 30px;
    width: auto;
  }
}

.form-control::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  opacity: 0.3; /* Firefox */
}

.style-card {

  h2 {
    font-size: 1.2rem;
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  background-color: #FFF;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, .14);
  border-radius: 3px;

  &-image-container {
    text-align: center;
    min-height: 15rem;
    max-height: 15rem;
    border-radius: 3px 3px 0 0;
    overflow: hidden;
    display: flex;
    justify-content: center;
    justify-items: center;
    position: relative;

    .no-image {
      background-image: linear-gradient(to bottom right, $primary, rgba(0, 105, 217, 0.3));
      width: 100%;
      height: 100%;
      display: block;
      position: absolute;
    }

    &-image {
      object-fit: cover;
      vertical-align: center;
    }

  }

  &-body {
    text-align: center;
    padding: 1rem;

    &-name {
      min-height: 3rem;
    }

    &-date {
      display: block;
      margin-bottom: 1.5rem;
    }
  }

}

.show-event {

  &-when-start {
    font-size: 1.5rem;

    &-icon {
      font-size: 1.5rem;
      color: $primary;
    }
  }

  &-when-end {
    font-size: 1rem;
    color: $gray-600;

    &-icon {
      font-size: .9rem;
    }
  }

  &-where {
    font-size: 1.2rem;

    &-icon {
      font-size: 1.5rem;
      color: $primary;
    }
  }

  &-image {
    border-radius: 3px;
    overflow: hidden;
  }

}

.event-public-list-organizer {
  &-icon {
    font-size: 1.5rem;
    color: $primary;
  }
}

#event-public-list-organizer {
  h1 {
    font-size: 1.5rem;
  }

  .material-icons {
    cursor: pointer;
    color: $primary;
  }

  .card-box {
    padding-bottom: 10px;

    #open-promo-code-form-button {
      padding-bottom: .675rem;
    }
  }

  width: 100%;
}

.event-ticket-category-list {
  background-color: $gray-100;

  h2 {
    font-size: 1rem;
    word-break: break-word;
    hyphens: auto;
    font-weight: bold;
  }

  .sold_out {
    color: #ee2200;
    font-weight: bold;
    display: block;
    vertical-align: middle;
  }

  .ticket-qty-container {

    @include media-breakpoint-down(sm) {
      margin-top: 1rem;
      display: flex;
    }

    input {
      width: 5rem;
      display: inline-block;

      @include media-breakpoint-down(sm) {
        flex-grow: 1;
      }
    }

    .ticket-qty-button {
      user-select: none;
      width: auto;
      background-color: $gray-200;
      @include media-breakpoint-down(sm) {
        flex: 1;
      }


      i {
        color: $gray-800;
        font-size: 18px;

        &:hover {
          color: darken($gray-800, 5%);
        }

        &:active {
          color: darken($gray-800, 10%);
        }
      }

      &:hover {
        background-color: darken($gray-200, 5%);
      }

      &:active {
        background-color: darken($gray-200, 10%);
      }

      &:focus {
        box-shadow: none;
      }
    }
  }
}

#order-summary {

  border-radius: 3px;

  h2 {
    font-size: 1.2rem;
  }

  table {
    border: 1px solid $gray-200;
    background-color: $gray-100;
    box-sizing: border-box;
    border-radius: 3px;
    border-collapse: collapse;
    overflow: hidden;

    thead {
      td {
        font-weight: bold;
      }
    }
  }

}

#order-count-down, #queue-count-down {

  .form-control {
    background-color: $gray-100;
  }

  text-align: center;

  &-timer {
    font-size: 1.5rem;
  }

}

#reservation-alert-countdown {
  font-size: 1.2rem;
}

#ticket-list {
  .sort, .btn {
    cursor: pointer;
  }

  .modal-footer {
    justify-content: normal;
  }

  .material-icons-asc {
    transform: rotate(180deg);
  }

  .material-icons {
    vertical-align: sub;
    padding-bottom: 0;
  }

  .product-name {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 300px;
  }

}

#ticket-list-mobile {
  .product-name {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 100%;
  }
}

h1, h2 {
  word-wrap: break-word;
  hyphens: auto;
  margin-bottom: 1.2rem;
}

h1 {
  @include media-breakpoint-down(sm) {
    font-size: 1.5rem;
  }
}

.material-icons {
  padding-bottom: 0.3rem;
}

#event-information {

  font-size: 1.2rem;

  ul {
    list-style: none;
  }

  .event-information-title {
    font-size: 1.5rem;
  }

}

.dropdown-divider {
  border-top: 1px solid $gray-700;
}

#organizer-payout-report {
  .sort, .btn {
    cursor: pointer;
  }

  .modal-footer {
    justify-content: normal;
  }

  .material-icons-asc {
    transform: rotate(180deg);
  }

  .material-icons {
    vertical-align: sub;
    padding-bottom: 0;
  }

}

.clickable-td {
  cursor: pointer;
}

.clickable-li {
  cursor: pointer;
}

.clickable-span {
  cursor: pointer;
}

.not-required {
  opacity: 0.45;
}

.auto-height {
  height: auto;
}

#organizer-sidebar-wrapper {
  @include media-breakpoint-down(sm) {
    display: none;
  }
}

#organizer-top-menu-wrapper {
  @include media-breakpoint-up(sm) {
    display: none;
  }
}

#restriction-code-form-button {
  color: $gray-600;
}

.restriction-code-ticket-category {
  border: 2px solid #46a0ff;
}

.html-new-line {
  white-space: pre-line;
}

#show-order-summary-toggle-button {
  color: $gray-600;
}

.breadcrumb {
  background-color: #ffffff;
}

#footer {
  font-size: 14px;

  a {
    color: #FFFFFF;
  }

  @include media-breakpoint-down(sm) {
    font-size: 16px;

    a {
      padding-top: 2px;
      padding-bottom: 2px;
      margin-top: 2px;
      margin-bottom: 2px;
      display: inline-block
    }
  }

  &-space-for-cart {
    height: 60px;
  }
}

#footer-organizer-information {
  @include media-breakpoint-down(sm) {
    ul {
      margin-bottom: 0;
    }
  }
}

@include media-breakpoint-down(sm) {
  div#menuLinks ul.navbar-nav a.btn-success {
    margin-top: .5rem;
  }
}


div#menuLinks {
  &.menu-organizer-container {
    .navbar-nav {
      @include media-breakpoint-down(md) {
        height: calc(100vh - 56px);
        overflow-y: auto;

        .organizer-menu-group-row {
          .organizer-menu-group {
            padding: 0;
          }
        }
      }
      @include media-breakpoint-up(lg) {
        max-height: calc(100vh - 56px);
        overflow-y: auto;

        .organizer-menu-group-row {
          padding-bottom: 10px;

          .organizer-menu-group {
            padding: 0 25px 0 0;
          }
        }
      }

      .nav-title {
        font-weight: bold;
        font-size: 1.2rem;
      }

      .nav-link {
        &.active {
          background: #808488;
          padding-left: 10px;
          display: inline-block;
          padding-right: 10px;
        }
      }

      .organizer-menu-group-row {
        margin: 0;
      }
    }
  }
}


@include media-breakpoint-up(lg) {
  #organizer-top-menu-container {
    display: none;
  }
}

.btn {
  touch-action: manipulation;
}

#modal-employer-token .alert {
  word-break: break-all;
}

.navbar-brand {
  padding-top: 0;
  padding-bottom: 0;

  img {
    max-height: 39px;
  }
}

body[p="frontpage"] {
  .container {
    width: 100%;
    max-width: 2550px;

    #event-public-list-search, #event-public-list {
      max-width: 1110px;
      margin: 0 auto;
    }
  }
}

body[p="events"] {
  @include media-breakpoint-only(md) {
    #content.is-employee {
      .container {
        max-width: 100%;
      }

      #navbar-product-category {
        margin: 0 auto;
      }

      .shop-employee-mode {
        .card-box {
          margin-bottom: 0;
        }
      }
    }
  }

  #content.is-employee {
    .product-category-title {
      font-size: 1.2rem;
      padding-left: 10px;
    }

    .confirmation-toast {
      .toast {
        background: #FFFFFF;
        max-width: 95vw;
      }
    }
  }
}

.last-pickup-reference{
  text-align: left;
  font-size: .8rem;
}
.order-info-wrapper span {
  border: 2px solid #46a0ff;
  display: inline-block;
  padding: 10px;
}

#area-zone-seat-icon {
  cursor: pointer;
}

#area-zone-seat-link {
  cursor: pointer;
}

#cart-modal {
  @include media-breakpoint-down(sm) {
    height: 100%;
  }

  overflow: auto;

  h1 {
    font-size: 1.5rem;
    margin-top: 15px;
    margin-bottom: 0;
  }

  #cart-wrapper {
    overflow-scrolling: auto;
  }

  .material-icons {
    vertical-align: middle;
    cursor: pointer;
    font-size: 28px;
  }

  .product-price {
    //width: 100px;
    display: inline-block;
  }

  .cart-price-and-amount {
    width: 165px;
  }

  #cart-products-wrapper {
    overflow-x: hidden;
  }

  .modal-body {
    overflow-y: auto;
  }

  td.product-title {
    cursor: pointer;
  }
}

#cart-modal .modal-dialog {
  @include media-breakpoint-down(sm) {
    height: 100%;
    margin: 0;
  }
}

#cart-modal .modal-content {
  border-radius: 0;
  @include media-breakpoint-down(sm) {
    height: 100%;
  }
}

#cart-term-modal {
  @include media-breakpoint-down(sm) {
    height: 100vh;
  }

  .modal-body {
    overflow-y: auto;
  }
}

#cart-term-modal .modal-dialog {
  @include media-breakpoint-down(sm) {
    margin: 0;
  }
}

#cart-term-modal .modal-content {
  border-radius: 0;
  @include media-breakpoint-down(sm) {
    height: 100vh;
  }
}

#table-order-summary {
  .product-name {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 300px;
  }

  .no-border {
    border: none;
  }
}

tr.product-extra td {
  border-top: none;
  font-size: 14px;
}

ul.product-extra li {
  font-size: 14px;
}

#product-extra-modal {
  z-index: 2000;

  @include media-breakpoint-down(sm) {
    height: 100vh;
  }

  overflow: auto;

  h1 {
    font-size: 1.5rem;
    margin-top: 15px;
    margin-bottom: 0;
  }

  #products-wrapper {
    max-height: 340px;
    overflow-x: hidden;
    overflow-y: scroll;

    @include media-breakpoint-down(sm) {
      text-align: center;
    }

  }

}

#product-extra-modal .modal-dialog {
  @include media-breakpoint-down(sm) {
    margin: 0;
  }
}

#product-extra-modal .modal-content {
  border-radius: 0;
  @include media-breakpoint-down(sm) {
    height: 100vh;
  }
}

.pickup_reference{
  font-size: 14rem;
}